
<template>
  <div class="dialTheNumber">
    <!--<div id="number">&nbsp;{{ number }}&nbsp;</div>-->
    <input id="number" :value="answers" disabled />

    <div id="numPad">
      <button v-for="n in 9" :key="n" class="keyButton bgWhite" @click="add(n)">
        {{ n }}
      </button>
      <button class="keyButton bgVeryLightGray"></button>
      <button class="keyButton bgWhite" @click="add(0)">0</button>
      <button class="keyButton bgVeryLightGray" @click="del">
        <div id="deleteKey">X</div>
      </button>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, computed } from "vue";
import { useAnswer } from "../useAnswer";

export default {
  name: "DialTheNumber",
  props: ["data", "isTest"],
  setup(props) {
    const { createRefAnswer, setAnswers } = useAnswer();
    const answers = createRefAnswer(props.taskId, "");

    onBeforeMount(() => {
      if (props.data) {
        setAnswers(answers, "");
      }
    });

    function add(n) {
      answers.value = answers.value.concat(n);
    }
    function del() {
      answers.value = answers.value.slice(0, -1);
    }

    return {
      answers: computed(() => answers.value),
      add,
      del,
    };
  },
};
</script>

<style lang="scss">
.dialTheNumber {
  #number {
    display: flex;
    background-color: var(--theme-color-first);
    border: 1px solid var(--theme-color-second);
    color: var(--theme-color-second);
    font-size: 1.5rem;
    padding: 5px 10px;

    width: 100%;
    max-width: 400px;
    margin: auto;
    margin-bottom: 25px;
    /*overflow: hidden;*/
  }

  #numPad {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    margin: auto;
    background-color: var(--theme-color-first);
    color: var(--theme-color-second);
    //touch-action: manipulation;

    .keyButton {
      display: flex;
      justify-content: center;
      width: 33.33333333%;
      font-weight: bold;
      font-size: 1.5rem;
      padding-top: 5%;
      padding-bottom: 5%;
      border: 1px solid var(--theme-color-second);
      /*cursor: pointer;*/

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    #deleteKey {
      position: relative;
      background: var(--theme-color-second);
      color: var(--theme-color-first);
      padding-left: 5px;
      padding-right: 5px;

      &:before {
        position: absolute;
        content: "";
        left: -10px;
        top: 7px;

        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid var(--theme-color-second);
      }
    }
  }
}
</style>
